<template>
  <b-modal id="UserValidSettingInfo" scrollable size="lg" centered hide-footer>
    <template #modal-title>
      <h3>
        <img src="@/assets/images/UserValidSettings.svg" class="icon-lg" />
        {{ $t("UserValidSettings.data") }}
      </h3>
    </template>
    <div class="">
      <div class="my-card">
        <div class="row">
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="userValidSetting.fullCode"
            :title="$t('UserValidSettings.code')"
            :imgName="'code.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="userValidSetting.userTypeNameCurrent"
            :title="$t('users.type')"
            :imgName="'type.svg'"
          />

          <DataLabelGroup
            :className="'col-lg-6'"
            :value="userValidSetting.userValidSettingNameAr"
            :title="$t('UserValidSettings.nameAr')"
            :imgName="'UserValidSettings.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="userValidSetting.userValidSettingNameEn"
            :title="$t('UserValidSettings.nameEn')"
            :imgName="'UserValidSettings.svg'"
          />

          <DataLabelGroup
            :className="'col-lg-6'"
            :value="userValidSetting.userValidSettingDescriptionAr"
            :title="$t('UserValidSettings.descriptionAr')"
            :imgName="'description.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="userValidSetting.userValidSettingDescriptionEn"
            :title="$t('UserValidSettings.descriptionEn')"
            :imgName="'description.svg'"
          />

          <DataLabelGroup
            :className="'col-lg-6'"
            :value="userValidSetting.userValidSettingNotes"
            :title="$t('notes')"
            :imgName="'notes.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="
              userValidSetting.userValidSettingActiveStatus == true
                ? $t('yes')
                : $t('no')
            "
            :title="$t('UserValidSettings.activeStatus')"
            :imgName="'type.svg'"
          />
        </div>
      </div>

      <div class="my-card">
        <span class="my-card-title">{{
          $t("UserValidSettings.requiredFields")
        }}</span>

        <div class="row">
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="
              userValidSetting.requiredUserImageStatus == true
                ? $t('yes')
                : $t('no')
            "
            :title="$t('UserValidSettings.UserImage')"
            :imgName="'type.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="
              userValidSetting.requiredUserFullCodeStatus == true
                ? $t('yes')
                : $t('no')
            "
            :title="$t('UserValidSettings.UserFullCode')"
            :imgName="'code.svg'"
          />

          <DataLabelGroup
            :className="'col-lg-6'"
            :value="
              userValidSetting.requiredUserEmailStatus == true
                ? $t('yes')
                : $t('no')
            "
            :title="$t('UserValidSettings.UserEmail')"
            :imgName="'email.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="
              userValidSetting.requiredUserPhoneStatus == true
                ? $t('yes')
                : $t('no')
            "
            :title="$t('UserValidSettings.UserPhone')"
            :imgName="'phone.svg'"
          />

          <DataLabelGroup
            :className="'col-lg-6'"
            :value="
              userValidSetting.requiredUserNationalIDStatus == true
                ? $t('yes')
                : $t('no')
            "
            :title="$t('UserValidSettings.UserNationalID')"
            :imgName="'nationalNumber.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="
              userValidSetting.requiredUserExpiryDateNationalIDStatus == true
                ? $t('yes')
                : $t('no')
            "
            :title="$t('UserValidSettings.UserExpiryDateNationalID')"
            :imgName="'date.svg'"
          />

          <DataLabelGroup
            :className="'col-lg-6'"
            :value="
              userValidSetting.requiredUserNotesStatus == true
                ? $t('yes')
                : $t('no')
            "
            :title="$t('UserValidSettings.UserNotes')"
            :imgName="'notes.svg'"
          />
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import DataLabelGroup from "@/components/general/DataLabelGroup.vue";
import { timeToLang } from "@/utils/functions";

export default {
  components: {
    DataLabelGroup,
  },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["userValidSetting"],
  methods: {
    timeToLang(time) {
      return timeToLang(time, this.language);
    },
  },
};
</script>

<style scoped lang="scss"></style>

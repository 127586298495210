<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <div v-if="userValidSettings.userValidSettingsData">
      <div class="row">
        <UserValidSettingCard
          v-for="(
            userValidSetting, index
          ) in userValidSettings.userValidSettingsData"
          :key="index"
          :defaultImg="userValidSettings.userValidSetting.defaultImg"
          v-on:setUserValidSettingData="
            userValidSettings.userValidSetting.fillData($event)
          "
          :userValidSetting="userValidSetting"
        />
      </div>

      <ActionsData :actionsData="userValidSettings.userValidSetting" />
      <UserValidSettingInfo
        :userValidSetting="userValidSettings.userValidSetting"
      />
      <UserValidSettingDelete
        :userValidSetting="userValidSettings.userValidSetting"
        v-on:refresh="getAllUserValidSettings()"
      />

      <Pagination
        v-if="!isLoading"
        :paginationData="userValidSettings.pagination"
        v-on:changePage="changePage"
      />
    </div>

    <UserValidSettingFloatBtns
      :theFilterData="userValidSettings.filterData"
      v-on:search="search($event)"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import createToastMixin from "@/utils/create-toast-mixin";
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "./../../../utils/constants";
import ActionsData from "@/components/general/ActionsData.vue";
import UserValidSettingCard from "@/components/settings/userValidSettings/UserValidSettingCard.vue";
import UserValidSettingInfo from "@/components/settings/userValidSettings/UserValidSettingInfo.vue";
import UserValidSettingDelete from "@/components/settings/userValidSettings/UserValidSettingDelete.vue";
import UserValidSettingFloatBtns from "@/components/settings/userValidSettings/UserValidSettingFloatBtns.vue";
import Pagination from "@/components/general/Pagination.vue";
import UserValidSettings from "./../../../models/settings/userValidSettings/UserValidSettings";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    ActionsData,
    UserValidSettingCard,
    UserValidSettingInfo,
    UserValidSettingDelete,
    UserValidSettingFloatBtns,
    Pagination,
  },
  computed: {
    ...mapGetters(["userAuthorizeToken"]),
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userValidSettings: new UserValidSettings(),
    };
  },
  methods: {
    changePage(page) {
      this.userValidSettings.pagination.selfPage = page;
      this.getAllUserValidSettings();
    },
    search(data) {
      this.userValidSettings.filterData.fillData(data);
      this.userValidSettings.userValidSettingsData = [];
      this.getAllUserValidSettings();
    },
    async getAllUserValidSettings() {
      this.isLoading = true;
      try {
        const response =
          await this.userValidSettings.userValidSetting.getAllUserValidSettings(
            this.language,
            this.userAuthorizeToken,
            this.userValidSettings.pagination,
            this.userValidSettings.filterData
          );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.userValidSettings.fillData(response.data);
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.userValidSettings.userValidSettingsData = null;
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.userValidSettings.userValidSettingsData = null;
          this.$store.dispatch("logoutUser");
        } else {
          this.userValidSettings.userValidSettingsData = null;
          this.exceptionMsg = response.data.msg;
        }
      } catch (error) {
        this.userValidSettings.userValidSettingsData = null;
        this.exceptionMsg = this.$t("errorCatch");
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.getAllUserValidSettings();
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"UserValidSettingInfo","scrollable":"","size":"lg","centered":"","hide-footer":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h3',[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/UserValidSettings.svg")}}),_vm._v(" "+_vm._s(_vm.$t("UserValidSettings.data"))+" ")])]},proxy:true}])},[_c('div',{},[_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.userValidSetting.fullCode,"title":_vm.$t('UserValidSettings.code'),"imgName":'code.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.userValidSetting.userTypeNameCurrent,"title":_vm.$t('users.type'),"imgName":'type.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.userValidSetting.userValidSettingNameAr,"title":_vm.$t('UserValidSettings.nameAr'),"imgName":'UserValidSettings.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.userValidSetting.userValidSettingNameEn,"title":_vm.$t('UserValidSettings.nameEn'),"imgName":'UserValidSettings.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.userValidSetting.userValidSettingDescriptionAr,"title":_vm.$t('UserValidSettings.descriptionAr'),"imgName":'description.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.userValidSetting.userValidSettingDescriptionEn,"title":_vm.$t('UserValidSettings.descriptionEn'),"imgName":'description.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.userValidSetting.userValidSettingNotes,"title":_vm.$t('notes'),"imgName":'notes.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.userValidSetting.userValidSettingActiveStatus == true
              ? _vm.$t('yes')
              : _vm.$t('no'),"title":_vm.$t('UserValidSettings.activeStatus'),"imgName":'type.svg'}})],1)]),_c('div',{staticClass:"my-card"},[_c('span',{staticClass:"my-card-title"},[_vm._v(_vm._s(_vm.$t("UserValidSettings.requiredFields")))]),_c('div',{staticClass:"row"},[_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.userValidSetting.requiredUserImageStatus == true
              ? _vm.$t('yes')
              : _vm.$t('no'),"title":_vm.$t('UserValidSettings.UserImage'),"imgName":'type.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.userValidSetting.requiredUserFullCodeStatus == true
              ? _vm.$t('yes')
              : _vm.$t('no'),"title":_vm.$t('UserValidSettings.UserFullCode'),"imgName":'code.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.userValidSetting.requiredUserEmailStatus == true
              ? _vm.$t('yes')
              : _vm.$t('no'),"title":_vm.$t('UserValidSettings.UserEmail'),"imgName":'email.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.userValidSetting.requiredUserPhoneStatus == true
              ? _vm.$t('yes')
              : _vm.$t('no'),"title":_vm.$t('UserValidSettings.UserPhone'),"imgName":'phone.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.userValidSetting.requiredUserNationalIDStatus == true
              ? _vm.$t('yes')
              : _vm.$t('no'),"title":_vm.$t('UserValidSettings.UserNationalID'),"imgName":'nationalNumber.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.userValidSetting.requiredUserExpiryDateNationalIDStatus == true
              ? _vm.$t('yes')
              : _vm.$t('no'),"title":_vm.$t('UserValidSettings.UserExpiryDateNationalID'),"imgName":'date.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.userValidSetting.requiredUserNotesStatus == true
              ? _vm.$t('yes')
              : _vm.$t('no'),"title":_vm.$t('UserValidSettings.UserNotes'),"imgName":'notes.svg'}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }